import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {companyLoaded, personLoaded, selectPersonRelation} from '../../core/actions';
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import Client from "./client";
import AlertInfo from "../feedback/AlertInfo";
import {isPerson} from "../../core/helper/actionHelper";

const ClientList = ({className}) => {
    const dispatch = useDispatch();
    const selectedProduct = useSelector(state => state?.product?.data) || [];
    const selectedClient = useSelector(state => state?.client?.data) || [];
    const relationsLoading = useSelector(state => state.product.relationsLoading);
    const relationsFailed = useSelector(state => state.product.relationsFailed);
    const [selectedClientId, setSelectedClientId] = useState(-1);

    const clientSelectedOnClick = (client, index) => {
        if (client && selectedClient && client.cluid === selectedClient.cluid) {
            return null;
        }
        setSelectedClientId(index);
        dispatch(selectPersonRelation(client, false));
    };

    const onRemove = (evt) => {
        evt.stopPropagation();
        evt.nativeEvent.stopImmediatePropagation();
        setSelectedClientId(-1);
    }

    return (
        <>
            {!relationsLoading ?
                !relationsFailed ?
                    <Row className={className}>
                        <Col md={12}>
                            {selectedProduct.relations.every(r => r.client) ?
                                <>
                                    <h5>Osoby navázané na produkt</h5>
                                    <Row className="mt-2">
                                        {selectedProduct.relations.map((r, index) =>
                                            <Col md={12} className="mb-2" key={(r.client.cluid + '-' + r.roleType)}>
                                                <Client compressed={index !== selectedClientId}
                                                        client={r.client}
                                                        onClick={(evt) => clientSelectedOnClick(evt, index)}
                                                        onRemove={onRemove}
                                                        relation={r}/>
                                            </Col>
                                        )}
                                    </Row>
                                </>
                                : null}
                        </Col>
                    </Row> :
                    <Row className={className}>
                        <Col md={12}>
                            <AlertInfo hideClose={true}
                                       label="Načtení osob navázaných na produkt selhalo."
                                       color="warning"/>
                        </Col>
                    </Row>
                : null}
        </>
    )
};

export default ClientList;